export const gewaltAmArbeitsplatz = {
  showing: 'video',
  courseId: "1002",
  title_: "Gewalt am Arbeitsplatz",
  courseTitle: {
    schulung: "Gewalt am Arbeitsplatz",
    training: "Violance in the workspace"
  },
  
  categories: ["Verkauf"],
  instructions: {},
  video: {
    autoplay: false,
    controls: true,
    fluid: true,
    responsive: true,
    preload: 'auto', 
    poster: "https://media.baeckerei-muenzel.de/muenzel-poster.png",
    sources: [{
      src: 'https://media.baeckerei-muenzel.de/schulungsfilm-gewalt-am-arbeitsplatz.mp4',
      type: 'video/mp4'
    }],
    controlBar: {
      playToggle: true,
      captionsButton: false,
      chaptersButton: false,            
      subtitlesButton: false,
      remainingTimeDisplay: true,
      progressControl: {
        seekBar: false
      },
      fullscreenToggle: true,
      playbackRateMenuButton: true,
    },
  },
  imgCourse: "",
  questions: [
    {
      idQuestion: "q1",
      imgQuestion: "/img/eins.jpg",
      question: "Der Täter verlangt alles Geld aus der Kasse.",
      answers: [
        "Der Täter bekommt nur das Wechselgeld",
        "Ja, ich gebe ihm das Geld.",
        "Nein, ich gebe ihm das Geld nicht.",
      ],
      correctAnswer: 1
    },
    {
      idQuestion: "q2",
      imgQuestion: "/img/eins.jpg",
      question: "Nachdem der Täter geflüchtet ist...",
      answers: [
        "... putze ich und mache alles ordentlich, damit alles und sauber ist wenn die Polizei kommt.",
        "... ich schließe die Filiale zu und gehe nach Hause.",
        "... ich rühre nichts an, damit die Polizei mögliche Spuren sichern kann.",
      ],
      correctAnswer: 2
    }
  ]
}
