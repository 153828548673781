import { Fragment  } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InputField } from './InputField'
import { useHistory } from 'react-router-dom'
// import { userCategoriesData } from './data'
import { db } from '../firebase'
import { collection, addDoc } from '@firebase/firestore';

export const DialogConfirmNumber = (props) => {
  const {isOpen, onChangeHandler, setIsOpen, disableConfirmButton, traineeNumberCheck, lessonProps } = props
  const history = useHistory()

  
  const submitLesson = async () => { 
    await addDoc(collection(db, "lessons"), lessonProps)
    .then(() => setIsOpen(false))
    .catch((error) => {
      alert(error)
    })
  }

  return (  
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-70"
        onClose={()=> setIsOpen(false)}
        >
        <div className="min-h-screen px-4 text-center">
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
            >
            &#8203;
            </span>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            >
            <div className="inline-block w-full max-w-md w-30 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
                >
                 
                  Personalnummer und Name
                </Dialog.Title>

                <div className="mt-2">
                  <div className="text-sm text-gray-500">
                  <InputField 
                    label={`Bitte geben Sie Ihre vierstellige Personalnummer ein. ${traineeNumberCheck}: ` }
                    type="text" 
                    placeholder="Personalnummer" 
                    name="traineeNumber" 
                    onChange={onChangeHandler} />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="text-sm text-gray-500">
                  
                  <InputField 
                    label="Bitte geben Sie Ihren Namen an:" 
                    type="text" 
                    placeholder="Name" 
                    name="traineeName" 
                    onChange={onChangeHandler} />
                  </div>
                </div>

           
  
  
                <div className="mt-4 flex justify-between">
                  <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={()=>  setIsOpen(false)}
                  >
                     cancel
                  </button>

                  <button
                      type="submit"
                      className="inline-flex disabled:bg-gray-100 disabled:text-gray-300 justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={()=>  {
                        submitLesson()
                        history.push('/lessons')
                      }}
                      disabled={disableConfirmButton} 
                  >
                     bestätigen
                  </button>
                </div>



            </div>
            </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
