import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { useState, useEffect, useContext, createContext } from 'react'
import { getDoc, doc } from '@firebase/firestore';

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  // const auth = getAuth();
  
  // const user = auth.currentUser;
  // 
  
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
  //   return  unsubscribe()      
  // }, [user])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      
      if (user) {
        setUser(user)
        const docRef = doc(db, "users", user.uid)
        const docSnap = await getDoc(docRef);
        const userDoc = docSnap.data()
        setIsAdmin(userDoc && userDoc?.roles.indexOf('admin') !== -1 ? true : false)
      } else {
        setUser(null)
        setIsAdmin(false)
      }
    }, setError)
       return () => unsubscribe()    
  }, [user])
  
  return <AuthContext.Provider value={{ user, error, isAdmin }} {...props} />
}

export function useUser() {
  const {user, error, isAdmin} = useContext(AuthContext)
  // setUser(user)
  
  
  return { user, error, isAdmin}
}

export const db = getFirestore()