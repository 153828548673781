import React from 'react';

export const InputField = (props) => {
  const { label, type, name, wid, value, id, disabled, placeholder, onChange} = props
  return (
    <div className={`${wid}flex justify-end`}>
        <label htmlFor="displayName" className="flex-shrink w-60 p-2">
          <span className="">{label}</span>
        </label>

        <div className="p-2 flex flex-1 items-start">
          <input 
            type={type}
            className={`p-1 bg-green-100 w-full disabled:bg-gray-100 text-black disabled:text-gray-400 border border-gray-300 rounded-md ${wid}` }
            name={name}
            value={value}
            id={id}
            placeholder={placeholder}
            onChange={onChange} 
            disabled={disabled} 
            autoComplete="current-password"
          />
      </div>
    </div> 
  );
}
 