import React,  {useState} from 'react';
import { DialogOverlay } from '../DialogOverlay';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { InputField } from "../InputField"
import { Button } from '../Button';
import { H1 } from '../Headline';
// import { useHistory } from 'react-router-dom'

export const ForgottenPassword = () => {
  const auth = getAuth();
  // const history = useHistory()
  
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Error')
  const [dialogDescription, setDialogDescription] = useState('unknown error')
  const [dialogButtonText, setDialogButtonText] = useState('cancel')
  
  const [email, setEmail] = useState('')

  const passwordReset = (ev) => {
    ev.preventDefault()
    
    sendPasswordResetEmail(auth, email)
    .then(() => {
      setDialogTitle('Password reset')
      setDialogDescription(`Eine Email wurde an folgende Adresse Versand: ${email}.
      Überprüfe Sie bitte Ihre E-Mails und folgen Sie diesem Link um Ihr Passwort 
      zu ändern.`)
      setDialogButtonText('ok')
      setDialogIsOpen(true)     
    })
    .catch((error) => {
      setDialogTitle('Sign up error: ' + error.code)
      setDialogDescription(error.message)
      setDialogButtonText('abbrechen!')
      setDialogIsOpen(true)
    })
  }

  
  const onChangeHandler = (ev) => {
    const {name,value} = ev.currentTarget
    if(name === 'email') {
      setEmail(value)
    }
  }

  // setDialogIsOpen
  return (  
    <div className="flex justify-around  p-5">
      <DialogOverlay isOpen={dialogIsOpen} historyPath="/" setIsOpen={setDialogIsOpen}  courseTitle={dialogTitle} description={dialogDescription} buttonText={dialogButtonText}/>
        <div className="p-5">
        <H1>
          Passwort 
          <br />
          vergessen?
        </H1>
        
        <div className='mt-10'>
          <label htmlFor="displayName" className="pl-3 flex-shrink w-60 ">
          Bitte geben Sie Ihre Email-Adresse ein. 
        </label>
        </div>
        
        <div>
          <label htmlFor="displayName" className="pl-3 flex-shrink w-60 ">
            Sie erhalten eine Email mit einem Link.
          </label>
        </div>
        <div className='mb-10'>
          <label htmlFor="displayName" className="pl-3 flex-shrink w-60 ">
            Folgen Sie diesem Link und ändern Ihr Passwort.     
          </label>
        </div>
      

        <InputField label="Email" type="email" name="email" placeholder="Email" onChange={onChangeHandler} id="email"  />
        <Button label="Passwort zurücksetzen" type="submit" onClick={passwordReset} />
      </div>
    </div>
  );
}
   