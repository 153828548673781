import { setDoc, doc, serverTimestamp, updateDoc } from '@firebase/firestore';
import { db } from '../../firebase'

export const createUserData = async (currentUser, userNumber, userName) => {
  console.log('userName: ', userName);
  console.log('userNumber: ', userNumber);
  console.log('user: ', currentUser);
  if (!currentUser) return
  const docRef = await doc(db, "users", currentUser.uid)
  
  if (!docRef){
    const payload = {
      userNumber,
      userName: currentUser.displayName ? currentUser.displayName : userName ,
      userEmail: currentUser.email, 
      roles: ['trainee'],
      createdAt: new Date(Date.now()).toDateString(),
      timestamp: serverTimestamp()
    }
    setDoc(docRef, payload)
      .catch((err) => alert('create', err))
  } 
}

export const updateUserData = async (user, userNumber, userName ) => {
  
  if (!user) return
  const docRef = await doc(db, "users", user.uid)

  if (!docRef){
    const payload = {
      userNumber,
      userName: user?.displayName ? user?.displayName : userName,
      userEmail: user?.email, 
      roles: ['trainee'],
      updatedAt: serverTimestamp()
    }
    setDoc(docRef, payload)
    .catch((err) => {
      console.log('err', err)
      alert('set', err)}
      )
  } else {
    const payload = {
      userNumber,
      userName,
      updatedAt: serverTimestamp()
    }
    updateDoc(docRef,  payload)

    .catch((err) => {
      console.log('err', err)
      alert('update', err)})
  }
}
