import React from 'react';
import {Link} from 'react-router-dom'
import { useUser } from '../../firebase';


export const Adminbar = () => {
  const {isAdmin} = useUser()
  // console.log('isAdmin: ', isAdmin);

  if (!isAdmin) return <></>
  return (
    <div className="bg-gray-200 text-gray-800 flex justify-end p-3">
    

      <div className='flex justify-end'>
      <div className='p-1'>
          <Link to="/unfilterd">ungefiltert</Link>
        </div>
        <div className='p-1'>|</div>
        <div className='p-1'>
          <Link to="/personal-schulungen-check"> Personal-Schulungen-Check</Link>
        </div>
        <div className='p-1'>|</div>

        <div className='p-1'>
          <Link to="/alluserlessons">Schulungen Check</Link>
        </div>
        <div className='p-1'>|</div>
        <div  className='p-1'>
          <Link to="/search">Search</Link> 
        </div>
      </div>
    </div>
  )
}