import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { collection, query, orderBy, getDocs , where} from "@firebase/firestore";
import { db } from '../../firebase'
import { format, differenceInMonths, subMonths } from 'date-fns'
import { de } from "date-fns/locale";
import { InputField } from "./../InputField";
import { SelectField } from "./../SelectField";

export function Search() {
  const [userLessonsArray, setUserLessonsArray] = useState([])  
  const [searchTerm, setSearchTerm] = useState('');
  const [subtractNumberOfMonthsFromToday, setSubtractNumberOfMonthsFromToday] = useState(7)

  
  useEffect(() => {
    let userLessons = []
    // const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"), limit(parseInt(limitt)));
    const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"), where("createdAt", ">=", subMonths( new Date(Date.now()), subtractNumberOfMonthsFromToday ) ))   
    
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        userLessons.push(doc.data())
      })
      setUserLessonsArray(userLessons)
    }
    fetchData()
  }, [subtractNumberOfMonthsFromToday])
  
  const handleSubtractNumberOfMonthsFromToday = (ev) => setSubtractNumberOfMonthsFromToday(ev.target.value)
  const handleSearch = (ev) => setSearchTerm(ev.target.value)
  
  return (
    <>
      <div className="flex w-full p-2 justify-between">

          <InputField
          value={searchTerm} 
          placeholder='Suche'
          onChange={handleSearch}
          wid='w-full'
          />
          
          <SelectField
          label={' Monate bis heute' }
          value={subtractNumberOfMonthsFromToday} 
          placeholder='Subtract Number Of Months From Today'
          onChange={handleSubtractNumberOfMonthsFromToday}
          />
     
        </div>
        <div className="p-5">
      

      { 
        userLessonsArray
          .filter(lesson => {  
            // console.log('lesson: ', lesson.courseTitle.schulung);
            return (
              lesson.traineeNumber.indexOf(searchTerm) !== -1 || lesson.traineeName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1  || (lesson.courseTitle ? lesson.courseTitle.schulung.toLowerCase().indexOf(searchTerm.toLowerCase()) : '' ) !== -1 // || user.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
              )
            })
            .map((lesson, index) => {
              // console.log('lesson: ', index);
              let { traineeName, courseTitle, traineeNumber, createdAt } = lesson
              // let createdAt =  moment(new Date(lesson.data.createdAt.seconds*1000)).format(' D.MM.YYYY, HH:mm') || 'heute'
              return (
                <div key={index} className="bg-gray-100 flex drop-shadow-lg p-2">
                  <div className="basis-60"> 
                      {traineeNumber} 
                  </div>
                  <div className="basis-4/12"> 
                      {courseTitle.schulung } 
                  </div>
                  <div className="basis-3/12"> 
                      {traineeName} 
                  </div>    
                  <div className="basis-2/12"> 
                    {format(new Date(createdAt.seconds * 1000), 'dd.MM.yyyy', { locale: de})}
                  </div>
                  <div className="basis-2/12">
                    vor {differenceInMonths(new Date(Date.now()), new Date(createdAt.seconds * 1000))} Monaten
                  </div>
              </div>
            )      
          })
        }     
        </div>
    </>
  )   
}

