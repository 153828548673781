import React from 'react';
import { sendEmailVerification, getAuth, signOut} from "firebase/auth";
import { Button } from '../Button';
import { H4 } from '../Headline';
import { Redirect, useHistory } from 'react-router';
import { useUser } from '../../firebase'

export const VerifyEmailAddress = () => {
  const { user } = useUser()
  const history = useHistory()
  
  const resendEmailVerification = (ev) => {
    ev.preventDefault()
    
    sendEmailVerification(user)
    .then(() => history.push('/verify-email-address'))
    .then(alert(`Bestätigungsemail wurde an ${user.email} gesendet`))
    // .then(() => signOut(getAuth()))
  }
  
  const Please = () => {
    return (
      <div className="flex justify-around p-5">
        {
          user ? 
            <div className="p-5 ">
              <H4>
              Wir haben ein E-Mail mit einem Bestätigungslink an 
                <p>
                  {user.email}
                </p>
              gesendet. Überprüfe Sie bitte Ihre E-Mails und <br />
              bestätigen Sie Ihre Anmeldung
            </H4>
            <H4>
            Keine Email bekommen: <br />
            </H4>
             <Button 
                type="submit"
                label="Bestätigungslink nochmal senden"
                onClick={resendEmailVerification}
              />
          </div>
         :
         <Redirect to='/courses' />        }
      </div>
    )
  }

  return (
    <div className="flex justify-around p-5">
      {
        user && user.emailVerified
        ? 
          <Redirect to='/courses' />
        :
          <Please />        
      }
    </div>
  )
  
}
 