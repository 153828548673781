import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom'
import Navbar from './Navbar'
import { Home } from './Home'
import { SignUp } from './auth/SignUp'
import { Login } from './auth/Login'
import { AuthContextProvider, useUser } from '../firebase'
import { Profile }  from './auth/Profile'
import { Quiz } from './quiz/Quiz'
import { Courses } from './Courses'
import { Course } from './Course'
import { ForgottenPassword } from './auth/ForgottenPassword'
import { VerifyEmailAddress } from './auth/VerifyEmailAddress'
import { UserLessons } from './user/UserLessons'
import { AllUserLessons } from './admin/allUserLessons'
import { Search } from './admin/Search'
import { Adminbar } from './admin/Adminbar'
import { Personal } from './admin/Personal'
import  { Unfiltered } from './admin/unfiltered'
// import { SelectField } from "./SelectField";
import { CRUD_Personal } from './personal'
// import { InputField } from "./InputField";

const PrivatRoute = ({component: Component, ...rest}) => {
  const { user } = useUser()


  return (
    <Route {...rest} 
      render={(props) =>  {   
      
      if ( user === null ) {
        return <Redirect to='/login' />
      } 
  
      if (user && user.emailVerified === false ) {
        return <Redirect to='/verify-email-address' />
      } 
  
      if (user && user.emailVerified === true ) {
        return <Component {...props} />
      } 
      }} 
    />
  )
} 


function App() {

  return (
    <AuthContextProvider>
      <Router>
        <Navbar />
        <Adminbar /> 
        <Route exact path="/personal-create" component={CRUD_Personal} />
        <Route exact path="/personal-schulungen-check" component={Personal} />
        <PrivatRoute exact path="/unfilterd" component={Unfiltered} />
        <PrivatRoute exact path="/search" component={Search} />
        <PrivatRoute exact path="/courses" component={Courses} />
        <PrivatRoute exact path="/alluserlessons" component={AllUserLessons} />
        <PrivatRoute exact path="/courses/:indexOfItem"  component={Course} />
        <PrivatRoute exact path="/" component={Home} />
        <PrivatRoute exact path="/profile" component={Profile} />
        <PrivatRoute exact path="/quiz" component={Quiz} />
        <PrivatRoute exact path="/lessons" component={UserLessons} />
        <Route exact path="/verify-email-address" component={VerifyEmailAddress} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
      </Router>
    </AuthContextProvider>
  )
}

export default App