import { setDoc, doc, serverTimestamp, updateDoc, arrayUnion } from '@firebase/firestore';
import { useState } from 'react';
import { db, useUser } from '../../firebase'
import { InputField } from '../InputField';
import { H1 } from '../Headline';
import { Button } from '../Button';

// export const createPersonalData = async (currentUser, userNumber, userName) => {
//   if (!currentUser) return

//   const docRef = await doc(db, "personal", personalnummer)
  
//   if (!docRef){
//     const payload = {
//       userNumber,
//       userName: currentUser.displayName ? currentUser.displayName : userName ,
//       userEmail: currentUser.email, 
//       roles: ['trainee'],
//       createdAt: new Date(Date.now()).toDateString(),
//       timestamp: serverTimestamp()
//     }
//     setDoc(docRef, payload)
//       .catch((err) => alert('create', err))
//   } 
// }


export const CRUD_Personal = () => {
  const { user } = useUser()
  console.log('user: ', user);
  
  // const history = useHistory()
  
  const [personalnummer, setPersonalnummer] = useState();
  const [abteilung, setAbteilung] = useState('');
  const [email, setEmail] = useState('');
  const [mobil,setMobil] = useState('');
  const [telefon, setTelefon] = useState('');
  const [vorname, setVorname] = useState('');
  const [nachname, setNachname] = useState('');
  const [roles, setRoles] = useState( ['trainee']);
  
  console.log('personalnummer: ', typeof personalnummer);
  
  // export const createUserData = async (currentUser, userNumber, userName) => {
  //   console.log('userName: ', userName);
  //   console.log('userNumber: ', userNumber);
  //   console.log('user: ', currentUser);
  //   if (!currentUser) return
  //   const docRef = await doc(db, "users", currentUser.uid)
    
  //   if (!docRef){
  //     const payload = {
  //       userNumber,
  //       userName: currentUser.displayName ? currentUser.displayName : userName ,
  //       userEmail: currentUser.email, 
  //       roles: ['trainee'],
  //       createdAt: new Date(Date.now()).toDateString(),
  //       timestamp: serverTimestamp()
  //     }
  //     setDoc(docRef, payload)
  //       .catch((err) => alert('create', err))
  //   } 
  // }

  const doCreatePersonal = async (ev) => {
    ev.preventDefault()
    if (!user) return
    console.log('hallo')
    // const docRef = await  doc(db, "personal", personalnummer)
    // console.log('docRef: ', docRef);
    // await setDoc(doc(db, "cities", "new-city-id"), data);

    // if (!docRef) {
    // }
      const payload = {
        personalnummer,
        abteilung,
        email,
        mobil,
        telefon,
        vorname,
        nachname,
        roles: arrayUnion(roles),
        createdAt: new Date(Date.now()).toDateString(),
        timestamp: serverTimestamp()
      }

      await setDoc(doc(db, "personal", personalnummer), payload , { merge: true })
        .catch((err) => alert('Error! Create', err))

    
    
    // else {
    //   alert('Ein Datensatz mit dieser Personalnummer existiert bereits. Bitte diese bearbeiten')
    // }
  }
    
    return (
      <div className="flex justify-around p-5">  
        <div className="p-5 ">
          <H1>Create Personal</H1>
          <form>
            <InputField label="Personalnummer" type="text" placeholder="Personalnummer" name="personalnummer" onChange={(event) => setPersonalnummer(event.target.value)} />
            <InputField label="Email" type="email" placeholder="email" name="email" onChange={(event) => setEmail(event.target.value)} />
            <InputField label="Abteilung" type="text" placeholder="Abteilung" name="abteilung" onChange={(event) => setAbteilung(event.target.value)} />
            <InputField label="Mobil" type="text" placeholder="Mobil" name="mobil" onChange={(event) => setMobil(event.target.value)} />
            <InputField label="Telefon" type="text" placeholder="Telefon" name="telefon" onChange={(event) => setTelefon(event.target.value)} />
            <InputField label="Vorname" type="text" placeholder="Vorname" name="vorname" onChange={(event) => setVorname(event.target.value)} />
            <InputField label="Nachname" type="text" placeholder="Nachname" name="nachname" onChange={(event) => setNachname(event.target.value)} />
            <InputField label="Roles" type="text" placeholder="Roles" name="roles" onChange={(event) => setRoles(event.target.value)} />
            <Button label="Create " type="submit" onClick={doCreatePersonal} />
          </form>
       </div>
      </div>
    )
  }