import React, { useEffect, useState }  from 'react';
import { collection, query, orderBy, where, getDocs } from "@firebase/firestore";
import { db, useUser } from '../../firebase'
import { format, differenceInMonths } from 'date-fns'
import {  EmojiHappyIcon, EmojiSadIcon } from "@heroicons/react/solid";
import { de } from 'date-fns/locale'
import { H4 } from '../Headline'
import { dataSet } from '../data/index';


const Emoji = function({month, monthSelected}) {
  if (month < monthSelected) return <EmojiHappyIcon className="h-10 m-1 text-green-400" />
  if (month === monthSelected) return <EmojiHappyIcon className="h-10 m-1 text-orange-400" />  
  return <EmojiSadIcon className="h-10 m-1 text-red-800" />          
}   

export const UserLessons = () => {
  const { user } = useUser()
  const [dateTime, setDateTime] = useState('');
  // const [monthSelected, setMonthSelected] = useState(5)
  const [userLessonsArray, setUserLessonsArray] = useState([])  
  const monthSelected = 5

  
  useEffect(() => {
    setDateTime(new Date(Date.now()))
    let userLessons = []
    const q =  query(collection(db, "lessons"), where("userId", "==", user.uid), orderBy("createdAt", "desc"));
    
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        userLessons.push(doc.data())
        
      })
      setUserLessonsArray(userLessons)
    }
    
    fetchData()
  }, [user.uid])
  
  
  function userLessonsStatusCheck(courseId) {
    let arr = userLessonsArray
    .filter(x => courseId === x.courseId)
    .sort((a, b) => a.createdAt.seconds < b.createdAt.seconds ? 1 : -1)
    
    if (arr.length === 0) return <div className='p-3'>  --- no --- </div> 
    
    return (   
          <div className='p-3 flex'>
            <div className='flex-auto'>
              zuletzt angesehen<br />am: {format(new Date(arr[0].createdAt.seconds * 1000), 'dd.MM.yyyy', { locale: de })}
            </div>
            <Emoji month={differenceInMonths(new Date(Date.now()), new Date(arr[0].createdAt.seconds * 1000))} monthSelected={monthSelected} />
          </div>
    )
  }

  function DataSetTableHeader (){ 
    return dataSet.map(x => {
      let courseId = userLessonsStatusCheck(x.courseId)

      return(
        <div key={x.courseId} className='bg-gray-100  flex-auto'> 
          <div className='bg-gray-400 p-3'>
            {x.courseTitle.schulung} 
          </div>
        
          <div className='p-3'>
            {courseId}
          </div>
        </div>
        )
      }
     )
  }

  return(
    <div className="flex justify-around  p-5">
      <div className="p-5">
          <div className='flex justify-between flex-wrap mb-28'>
              <DataSetTableHeader />
          </div>
       
        <H4>
          Historie von {user.displayName || user.email}:       
        </H4>
        <div className={`bg-gray-50 rounded-lg shadow-lg`} >
          <ul className="divide-y-2 divide-gray-100">
    
            {userLessonsArray.map(lesson => {
              // console.log('lesson: ', lesson);
              const courseTitleSchulung = lesson.courseTitle.schulung
              const createdAt = lesson.createdAt.seconds
              const month = differenceInMonths(new Date(dateTime), new Date(lesson.createdAt.seconds * 1000))

              return (
                <li key={lesson.createdAt.seconds}>
                  <div className="p-3 w-full flex  justify-start text-primary hover:bg-primary hover:text-gray-100  rounded-md" >
                    <Emoji month={month} monthSelected={monthSelected} />
                    <div className='flex justify-start pl-5'>

                      <div className="flex-auto font-bold">
                        {courseTitleSchulung}
                      <div className="font-normal w-full flex  justify-between ">
                        <div>angesehen am: {format(new Date(createdAt * 1000), 'dd. MMMM yyyy', { locale: de })}</div> <div>{': '}  vor {month} Monaten</div>
                      </div>
                    </div>
                    </div>                          
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

