export const mehlstaubNeinDanke = {
  showing: 'video',
  courseId: "1003",
  courseTitle: {
    schulung: "Mehlstaub nein danke",
    training: "Flour dust - no thanks",
  },
  title_: "Mehlstaub nein danke",
  categories: ["Produktion"],
  instructions: {},
  video: {
    autoplay: false,
    controls: true,
    fluid: true,
    responsive: true,
    preload: 'auto', 
    poster: "https://media.baeckerei-muenzel.de/muenzel-poster.png",
    sources: [{
      src: 'https://media.baeckerei-muenzel.de/schulungsfilm-mehlstaub-nein-danke.mp4',
      type: 'video/mp4'
    }],
    controlBar: {
      playToggle: true,
      captionsButton: false,
      chaptersButton: false,            
      subtitlesButton: false,
      remainingTimeDisplay: true,
      progressControl: {
        seekBar: false
      },
      fullscreenToggle: true,
      playbackRateMenuButton: true,
    },
  },
  imgCourse: "",
  questions: [
    {
      idQuestion: "q1",
      imgQuestion: "/img/eins.jpg",
      question: "Wer staubarm arbeitet lebt gesünder?",
      answers: [
        "Nein, Mehlstaub ist gesund",
        "Ja, man lebt gesünder und muss weniger reinigen.",
        "Mehlstaub ist Nahrhaft"
      ],
      correctAnswer: 1
    },
    {
      idQuestion: "q2",
      imgQuestion: "/img/eins.jpg",
      question: "Eine geringe Fallhöhe verursacht viel Mehlstaub",
      answers: [
        "Nein, eine niedrige Fallhöhe reduziert Mehlstaub.",
        "Ja, je größer die Fallhöhe, destowendiger staubt es.",
        "Mehr Staub bedeudet bessere Arbeit "
      ],
      correctAnswer: 0
    }
  ]
}