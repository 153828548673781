import React from 'react';

export const SelectField = (props) => {
  const { label, type, name, value, id, disabled, placeholder, onChange} = props
  const dataArr = [1,2,3,4,5,6,7,8,9,10,11,12,15,18,21,24,36,48]
  return (
    <div className='flex justify-end'>
      <div className="p-2 flex items-start">
      <select 
        type={type}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange} 
        disabled={disabled} 
        // autoComplete="current-password"
        className="p-1 bg-gray-100 w-20 disabled:bg-gray-100 text-black disabled:text-gray-400 border border-gray-300 rounded-md"
      >           
        {dataArr.map((item, index) => {
          return (
              <option key={index }value={item}>{item}</option>
              )
            })}
        </select>
        </div>
      <label htmlFor="displayName" className="p-2 flex-shrink w-60 ">
        <span className="">{label}</span>
      </label>
    </div>
  );
}
 