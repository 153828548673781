import React, { useEffect, useState }  from 'react';
import { collection, query, getDocs, where} from "@firebase/firestore";
import { db } from '../../firebase'
import { format, differenceInMonths,subMonths } from 'date-fns'
import { de } from 'date-fns/locale'
import { SelectField } from '../SelectField';

export const Personal = () => {
  const [personalFiltered, setPersonalFiltered] = useState()
  const [userLessonsArray, setUserLessonsArray] = useState([])  
  const [subtractNumberOfMonthsFromToday, setSubtractNumberOfMonthsFromToday] = useState(1)
  // const [subtractNumberOfMonthsFromToday, setSubtractNumberOfMonthsFromToday] = useState(5)
  
  const handleSubtractNumberOfMonthsFromToday = (ev) => setSubtractNumberOfMonthsFromToday(ev.target.value)
  

  useEffect(() => {
    let userLessons = []
    // const q =  query(collection(db, "lessons"), limit(50))
    const q =  query(collection(db, "lessons"), where("createdAt", ">=", subMonths( new Date(Date.now()), 12 ) ))
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        userLessons.push(doc.data())   
      })
      setUserLessonsArray(userLessons)
    }
    fetchData()
  }, [])
  
  useEffect(() => {
    let personalArr = []
    const q =  query(collection(db, "personal"))
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        personalArr.push(doc.data())
      })

      const filterLessons = await personalArr.map(item => {
        let lessons = userLessonsArray.filter(f => {
          return  parseInt(f.traineeNumber) === parseInt(item.personalnummer)
        })
        return {"pers": item, "less": lessons}
      })

      setPersonalFiltered(filterLessons)
    }
    fetchData()
  }, [userLessonsArray])
  
  
  function Less(lessons) {
    const less = lessons.lessons
    // console.log('lessonslength: ', less.length);
    if (less.length > 0) {
      return (
        <div className='w-full mb-3 rounded-b-lg'>
          {less.map((lesson, index) => {
            let month = differenceInMonths(new Date(Date.now()), new Date(lesson.createdAt.seconds * 1000))
            let bgcolor = (month > subtractNumberOfMonthsFromToday) ? 'bg-red-100' : 'bg-green-100'
            return (
             <div key={index} className={`flex w-full justify-between ${bgcolor}`}>
               <div className='basis-1/12 p-2 truncate ...'>{lesson.traineeNumber}</div>
               <div className='basis-4/12 p-2 truncate ...'>{lesson.courseTitle.schulung + "/" + lesson.courseTitle.training}</div>
               <div className='basis-2/12 p-2 truncate ...'>angesehen am: {format(new Date(lesson.createdAt.seconds * 1000), ' dd.MM.yyyy', {loacle: de})} </div>
               <div className='basis-5/12 p-2 truncate ...'>vor {month} Monaten</div>
             </div>
            )
          })}
        </div>
      )
    } else {
      return (
        <div className='flex w-full justify-between bg-red-100 mb-3 '>
        <div className='p-2 w-full truncate ...'>keine Schulungen in den letzten 12 Monaten!</div>
        </div>
      )
    }
  }

  return (
    <>
    
    
      <div className="p-5">
      <SelectField
          label={' Warnschwelle in Monaten'}
          value={subtractNumberOfMonthsFromToday} 
          placeholder='Subtract Number Of Months From Today'
          onChange={handleSubtractNumberOfMonthsFromToday}
          />
        {
          personalFiltered && personalFiltered.map((item, index) => {
            const lessons = item.less
            const personal = item.pers
            return (
              <div key={index} className='w-full drop-shadow-lg'>
                <div className='flex w-full justify-between bg-gray-300  border-b-2 border-gray-300'>
                  <div className='basis-1/12 p-2 truncate ...'>{personal.personalnummer} </div>
                  <div className='basis-2/12 p-2 truncate ...'>{personal.vorname} </div>
                  <div className='basis-2/12 p-2 truncate ...'>{personal.nachname} </div>
                  <div className='basis-1/12 p-2 truncate ...'>{personal.abteilung} </div>
                  <div className='basis-2/12 p-2 truncate ...'>{personal.email} </div>
                  <div className='basis-2/12 p-2 truncate ...'>{personal.telefon} </div>
                  <div className='basis-2/12 p-2 truncate ...'>{personal.mobil} </div>
                </div>  
                <Less lessons={lessons} />
              </div>
            )
          }) 
        }
    
      </div>
    </>
  )
}