import { Redirect } from 'react-router'
import { useUser } from './../firebase'

export const Home = () => {
  const { user } = useUser()

  if (user ) return <Redirect to="/lessons" />
  return (


        <Redirect to="/" />        

  )
}