import React, { useEffect, useState }  from 'react';
import { collection, query, getDocs, where} from "@firebase/firestore";
import { db } from '../../firebase'

import { dataSet } from '../data/index';
import { format, differenceInMonths, subMonths } from 'date-fns'
import { EmojiHappyIcon, EmojiSadIcon } from "@heroicons/react/solid";
import { de } from 'date-fns/locale'
import { Popover } from '@headlessui/react'
import { SelectField } from '../SelectField';



const Emoji = function({month, monthSelected}) {
  if (month < monthSelected) return <EmojiHappyIcon className="h-9  text-green-100 " />
  if (month === monthSelected) return <EmojiHappyIcon className="h-9  text-orange-100 " />  
  return <EmojiSadIcon className="h-9  text-red-200 " />          
}  

export const AllUserLessons = () => {
  const [userLessonsArray, setUserLessonsArray] = useState([])  
  const [lessonStatus, setLessonStatus] = useState([])
  const [subtractNumberOfMonthsFromToday, setSubtractNumberOfMonthsFromToday] = useState(6)
  
  const handleSubtractNumberOfMonthsFromToday = (ev) => setSubtractNumberOfMonthsFromToday(ev.target.value)
  
  useEffect(() => {
    let userLessons = []
    // const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"), limit(20));
    // const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"), where("createdAt", ">=", subMonths( new Date(Date.now()), subtractNumberOfMonthsFromToday ) ))
    // const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"))
    const q =  query(collection(db, "lessons"), where("createdAt", ">=", subMonths( new Date(Date.now()), 12 ) ))
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        userLessons.push(doc.data())
        
      })
      setUserLessonsArray(groupBy(userLessons, 'traineeNumber'))
    }
    
    fetchData()
  }, [])

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
       const key = obj[property];
       if (!acc[key]) {
          acc[key] = [];
       }
       // Add object to list for given key's value
       acc[key].push(obj);
       return acc;
    }, {});
  }

  useEffect(()=> {
    let filteredAndSortedArrayPerUSer = []
    
    Object.values(userLessonsArray)
      .map(lessonsPerUser => {
        let filteredAndSortedArray = []
      
        dataSet.forEach(dataSetItem => {
          let arr = lessonsPerUser
          .filter(x => dataSetItem.courseId === x.courseId)
          .sort((a, b) => a.createdAt.seconds < b.createdAt.seconds ? 1 : -1)
          
          filteredAndSortedArray.push(arr[0])
        });   
    return filteredAndSortedArrayPerUSer.push(filteredAndSortedArray)  
  })

  setLessonStatus(filteredAndSortedArrayPerUSer)
  }, [userLessonsArray, subtractNumberOfMonthsFromToday])

  function PerUser() {
    let userStatus = lessonStatus.map((trainee, index) => {
      let traineeNameLesson = ''
      let traineeNumberLesson =''
      let traineeCategoryLesson = ''
      let traineelessons = trainee.map((less, index) => {
        if (traineeNameLesson.length === 0 && less?.traineeName) traineeNameLesson = less.traineeName
        if (traineeNameLesson.length === 0 && less?.userName) traineeNameLesson = less.userName
        if (traineeNumberLesson.length === 0 && less?.traineeNumber) traineeNumberLesson = less.traineeNumber
        if (traineeCategoryLesson.length === 0 && less?.traineeCategories) traineeCategoryLesson = less.traineeCategories[0]
       
        if (!less) return (
            <div key={index} className='flex bg-gray-50 p-1 w-40 '>
              <div className='' > </div>
              <p className=' p-1'> --- </p> 
            </div>
        )
             
        return (
        
            <div key={index} className='flex'>
              <Popover className="relative">
                <Popover.Button>
                  <div className='flex justify-between hover:bg-gray-200 p-1 w-40 '>
                  <span className='align-middle truncate ...'>
                    {less.courseTitle.schulung}
                  </span>
                  <p className='p-1'>
                  <Emoji month={differenceInMonths(new Date(Date.now()), new Date(less.createdAt.seconds * 1000))} monthSelected={subtractNumberOfMonthsFromToday} />
                  </p>
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute -m-7 z-10 bg-secondary p-3 rounded-lg">
                <div className="">
                <p>{less.courseTitle.schulung} zuletzt angesehen am: 
                
                {format(new Date(less.createdAt.seconds * 1000), ' dd.MM.yyyy', { locale: de })}
                </p> 
                </div>

              </Popover.Panel>
            </Popover>
          </div>
        )
      })
     
      return (

          <div key={index} className='bg-gray-100 rounded-md flex justify-between border-t-2 border-gray-300'>

            <div  className='pt-2 pl-2 w-24'>
              {traineeNumberLesson}
            </div>
            <div  className='flex p-1'>
              {traineelessons}
            </div>
            <div  className='flex-auto p-3'>
              {traineeNameLesson}
            </div>
       
          </div>
        )
    })

    return (
      <div>

     
        {userStatus} 
      </div>
    ) 
  }

  return (
    <>
    <div className="flex justify-around  p-5">
      <div className="p-5">
         <SelectField
          label={' Warnschwelle in Monaten'}
          value={subtractNumberOfMonthsFromToday} 
          placeholder='Subtract Number Of Months From Today'
          onChange={handleSubtractNumberOfMonthsFromToday}
          />
        <div className={`bg-gray-50 rounded-lg shadow-lg p-1`} >
          <PerUser />
        </div>
      </div>
    </div>
    </>
  )
}