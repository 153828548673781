import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from '../../firebase'
import { getAuth, signOut,   updateProfile, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { signUpWithGoogle } from '../../provider'
import { DialogOverlay } from '../DialogOverlay'
import { Button } from "../Button"
import { InputField } from "../InputField"
import { H1 } from '../Headline'
import { createUserData } from '../user/UserProfile'
// import { userCategoriesData } from '../data'

export const SignUp = () => {
  const auth = getAuth()
  const { user } = useUser()
  // console.log('user: ', user);
  
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Error')
  const [dialogDescription, setDialogDescription] = useState('unknown error')
  const [dialogButtonText, setDialogButtonText] = useState('cancel')
   
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [userNumber, setUserNumber] = useState('')
  
  const onChangeHandler = (ev) => {
    ev.preventDefault()
    const { name, value } = ev.currentTarget
    
    if (name === "email") setEmail(value)
    if (name === "password") setPassword(value)
    if (name === "passwordConfirm") setPasswordConfirm(value)
    if (name === "userName") setUserName(value)
    if (name === "userNumber") setUserNumber(value)
  }

  const signUpWithEmail = async(ev) => {
    ev.preventDefault()
 
    if (password !== passwordConfirm) {
      setDialogTitle('Passwörter stimmen nicht überein.')
      setDialogDescription('Die Passwörter stimmen nicht überein.')
      setDialogIsOpen(true)
      return
    }

    if (!userName) {
      setUserName(email)
    }
  

    if (!userNumber) {
      setDialogTitle('Das Feld Personalnummer ist leer.')
      setDialogDescription('Bitte geben Sie Ihre Personalnummer an.')
      setDialogIsOpen(true)
      return
    }
    if (!email) {
      setDialogTitle('Das Feld Email ist leer.')
      setDialogDescription('Bitte geben Sie Ihre  Email an.')
      setDialogIsOpen(true)
      return
    }

    createUserWithEmailAndPassword(auth, email, password)
    .then((x) => {
      console.log('x.user.email: ', x.user.email,  ' auth.currentUser: ', auth.currentUser, 'process.env.REACT_APP_HOMEPAGE: ', process.env.REACT_APP_HOMEPAGE)
      const actionCodeSettings = {
        url: process.env.REACT_APP_HOMEPAGE + '/',
        handleCodeInApp: false,
      }
      
      sendEmailVerification(auth.currentUser, actionCodeSettings )
    })
    .then(() => updateProfile(auth.currentUser, userNumber,  {displayName: userName, })
    .then(() =>   {
      createUserData(auth.currentUser, userNumber, userName )
    })
    .then(() => {
      setDialogTitle('Bestätigen Sie Ihre Email Adresse')
      setDialogDescription('Wir haben Ihnen ein E-Mail an mit einem Bestätigungslink gesendet. Überprüfe Sie bitte Ihre E-Mails und bestätigen Sie Ihre Anmeldung. Erst dann können Sie sich mit Ihrem Passwort und Ihrer Email einloggen.')
      setDialogButtonText('Ok')
      setDialogIsOpen(true)
      // history.push('/verify-email-address')
    })
    .then(() => signOut(getAuth()))
    // .then(()=> history.push('/login'))
    )
    .catch((err) => {
      setDialogTitle('Sign up error')
      setDialogDescription(err.message)
      setDialogButtonText('Oh no!')
      setDialogIsOpen(true)
    })
  }
  return (
    <>
      { user && user?.emailVerified 
        ? 
        <Redirect to='/courses' />
        :
        
        <div className="flex justify-around  p-5">
        <DialogOverlay isOpen={dialogIsOpen} historyPath="/verify-email-address" setIsOpen={setDialogIsOpen} title={dialogTitle} description={dialogDescription} buttonText={dialogButtonText}/>
   
        <div className="p-5  ">
    
          <H1>SignUp</H1>
          <form >
            <InputField label="Name" type="text" name="userName" placeholder="Name" onChange={onChangeHandler} />
            <InputField label="Personalnummer" type="text" name="userNumber" placeholder="Personalnummer" onChange={onChangeHandler} />
            <InputField label="Email" type="email" name="email" placeholder="email" onChange={onChangeHandler} />
            <InputField label="Password" type="password" name="password" placeholder="password" onChange={onChangeHandler} />
            <InputField label="Password Confirmation" type="password" name="passwordConfirm" placeholder="password confirmation" onChange={onChangeHandler} />
            <Button label="Sign up with Email" type="submit" onClick={signUpWithEmail} />
            <Button label="Login with Google" type="submit" onClick={signUpWithGoogle}/>
          </form>
        </div>
      </div>
      
      }
    </>
  )
}