import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { collection, query, orderBy, getDocs , where} from "@firebase/firestore";
import { db } from '../../firebase'
import {  subMonths } from 'date-fns'
import { SelectField } from "./../SelectField";
// import { format, differenceInMonths, subMonths } from 'date-fns'
// import { de } from "date-fns/locale";
// import { InputField } from "./../InputField";

export function Unfiltered() {
  const [userLessonsArray, setUserLessonsArray] = useState([])  
  const [subtractNumberOfMonthsFromToday, setSubtractNumberOfMonthsFromToday] = useState(1)

  
  useEffect(() => {
    let userLessons = []
    const q =  query(collection(db, "lessons"), orderBy("createdAt", "desc"), where("createdAt", ">=", subMonths( new Date(Date.now()), subtractNumberOfMonthsFromToday ) ))   
    
    async function fetchData () {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        userLessons.push(doc.data())
      })
      setUserLessonsArray(userLessons)
    }
    fetchData()
  }, [subtractNumberOfMonthsFromToday])
  
  const handleSubtractNumberOfMonthsFromToday = (ev) => setSubtractNumberOfMonthsFromToday(ev.target.value)
  
  return (
    <>
      <div className="flex w-full p-2 justify-between">
        <SelectField
          label={' Monate bis heute' }
          value={subtractNumberOfMonthsFromToday} 
          placeholder='Subtract Number Of Months From Today'
          onChange={handleSubtractNumberOfMonthsFromToday}
        />
      </div>
        <div className="p-5">
          { 
            userLessonsArray
              .map((lesson, index) => {
                return (
                  <div key={index} className="bg-gray-100 flex drop-shadow-lg p-2">
                    <div className=""> 
                        {JSON.stringify(lesson)} 
                    </div>
                </div>
              )      
            })
          }     
        </div>
    </>
  )   
}

